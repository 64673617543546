import { Container, Col, Button, Row, Form } from 'react-bootstrap';
import classes from './Playground.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import BeanAttributes from './beans-attributes.json';
import Bean from '../../components/Bean';
import BeanModal from './BeanModal';
import axios from 'axios';
import beansLoadingAnimation from '../../assets/lottie/loading_bean.json';
import Lottie from 'lottie-react';

interface Trait {
  title: string;
  traitNames: string[];
  traitValues: string[];
}

const customStyles = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    background: '#191B1F',
    border: 'none',
    borderRadius: 12,
    textAlign: 'left',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? 'red' : 'blue',
    },
    fontSize: 10,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
    hyphens: 'auto',
    // kill the gap
    marginTop: 0,
    textAlign: 'left',
    fontSize: 10,
    // border: "1px solid black",
    // prevent menu to scroll y
    // wordWrap: "break-word"
  }),
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    color: state.isSelected ? 'var(--beans-color-primary)' : '#E6E6E6',
    background: '#292C33',
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, transition };
  },
};

const parseTraitName = (partName: string): string =>
  capitalizeFirstLetter(partName.substring(partName.indexOf('-') + 1));

const capitalizeFirstLetter = (s: string): string =>
  s.charAt(0).toUpperCase() + s.slice(1);

const Playground: React.FC = () => {
  const INITIAL_BEANS = 12;
  const RANDOM_VALUE = '0';
  const [beanSvgs, setBeanSvgs] = useState<string[]>();
  const [traits, setTraits] = useState<Trait[]>();
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [displayBean, setDisplayBean] = useState<boolean>(false);
  const [indexOfBeanToDisplay, setIndexOfBeanToDisplay] = useState<number>();
  const [selectIndexes, setSelectIndexes] = useState<Record<string, string>>({
    'Class 1': RANDOM_VALUE,
    'Class 2': RANDOM_VALUE,
    Gear: RANDOM_VALUE,
    Helmet: RANDOM_VALUE,
    Size: RANDOM_VALUE,
    Vibe: RANDOM_VALUE,
  });
  const traitTitles = useMemo(
    () => ['Class 1', 'Class 2', 'Size', 'Gear', 'Helmet', 'Vibe'],
    []
  );

  const generateBeanSvg = React.useCallback(
    async (amount: number = 1, selectedTraits: Record<string, string>) => {
      const payload = {
        count: amount,
        cls1:
          selectedTraits['Class 1'] === '0' ? '0' : selectedTraits['Class 1'],
        cls2:
          selectedTraits['Class 2'] === '0' ? '0' : selectedTraits['Class 2'],
        size:
          selectedTraits['Size'] === '0' ? 0 : Number(selectedTraits['Size']),
        gear:
          selectedTraits['Gear'] === '0' ? 0 : Number(selectedTraits['Gear']),
        helmet:
          selectedTraits['Helmet'] === '0'
            ? 0
            : Number(selectedTraits['Helmet']),
        eyes:
          selectedTraits['Vibe'] === '0' ? 0 : Number(selectedTraits['Vibe']),
      };

      var res = await axios.post(
        `https://beans-playground-b04775d0759f.herokuapp.com/apis/buildAbean/`,
        payload
      );
      var beans = res.data.beans;
      for (let i = 0; i < beans.length; i++) {
        var svgObj = beans[i];
        let svg = svgObj;
        setBeanSvgs((prev) => {
          return prev ? [svg, ...prev] : [svg];
        });
      }
    },
    []
  );

  useEffect(() => {
    const traitNames = [
      ...Object.values(BeanAttributes.beanparts).map((i) => {
        return i.map((imageData) => imageData.name.toString());
      }),
    ];
    const traitValues = [
      ...Object.values(BeanAttributes.beanparts).map((i) => {
        return i.map((imageData) => imageData.value);
      }),
    ];
    setTraits(
      traitTitles.map((value, index) => {
        return {
          title: value,
          traitNames: traitNames[index],
          traitValues: traitValues[index],
        };
      })
    );

    if (initLoad) {
      generateBeanSvg(INITIAL_BEANS, selectIndexes);
      setInitLoad(false);
    }
  }, [generateBeanSvg, initLoad, selectIndexes, traitTitles]);

  const traitOptions = (trait: Trait) => {
    return Array.from(Array(trait.traitNames.length + 1)).map((_, index) => {
      const parsedTitle =
        index === 0
          ? `RANDOM`
          : parseTraitName(trait.traitNames[index - 1]).toUpperCase();
      let actualValue = trait.traitValues[index - 1];
      if (!actualValue) {
        actualValue = '0';
      }
      return { value: actualValue, label: parsedTitle };
    });
  };

  return (
    <>
      {displayBean && indexOfBeanToDisplay !== undefined && beanSvgs && (
        <BeanModal
          onDismiss={() => {
            setDisplayBean(false);
          }}
          svg={beanSvgs[indexOfBeanToDisplay]}
        />
      )}

      <Container fluid='lg' className='playgroundContainer'>
        <Row className='justify-content-center'>
          <Col lg={8} className={classes.headerRow}>
            <h1>PLAYGROUND</h1>
            <p>
              What Bean can you dream up? Create and share your own using the
              Playground!
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {traits &&
                traits.map((trait, index) => {
                  return (
                    <Col xs={6} lg={12}>
                      <Form className={classes.traitForm}>
                        <div>
                          <span className={classes.traitTitle}>
                            {' '}
                            {trait.title.toUpperCase()}{' '}
                          </span>
                          <Select
                            menuShouldScrollIntoView={false}
                            isSearchable={false}
                            autoFocus={false}
                            blurInputOnSelect={true}
                            key={`select-input-${index}`}
                            styles={customStyles}
                            options={traitOptions(trait)}
                            defaultValue={{
                              label: 'RANDOM',
                              value: '0',
                            }}
                            onChange={(e) => {
                              if (e && e.label && e.value) {
                                setSelectIndexes({
                                  ...selectIndexes,
                                  [trait.title]: e.value,
                                });
                              }
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: 'var(--beans-color-primary)',
                                primary25: '#000000',
                                primary50: 'var(--beans-color-primary)',
                                primary75: 'pink',
                                danger: 'pink',
                                dangerLight: 'pink',
                                neutral0: '#191B1F', // component background
                                neutral5: 'pink',
                                neutral10: 'pink',
                                neutral20: '#676C7A', // border and dropdown caret
                                neutral30: 'transparent', // component hover outline
                                neutral40: '#676C7A', // caret hover
                                neutral50: 'pink',
                                neutral60: '#676C7A', // caret active hover
                                neutral70: 'pink',
                                neutral80: '#CCCCCC', // component text color
                                neutral90: 'pink',
                              },
                            })}
                          />
                        </div>
                      </Form>
                    </Col>
                  );
                })}
            </Row>
            <Button
              onClick={() => {
                generateBeanSvg(1, selectIndexes);
              }}
              className={classes.generateBtn}
            >
              GENERATE BEANS
            </Button>
            <p className={classes.beanYearsFooter}>
              YOU'VE GENERATED{' '}
              {beanSvgs ? (beanSvgs.length / 365).toFixed(2) : '0'} YEARS WORTH
              OF BEANS
            </p>
          </Col>
          <Col lg={9}>
            <Row>
              {!beanSvgs &&
                Array(INITIAL_BEANS)
                  .fill(1)
                  .map((el, i) => (
                    <Col xs={4} lg={3}>
                      <Lottie
                        animationData={beansLoadingAnimation}
                        loop={true}
                        autoplay={true}
                      />
                    </Col>
                  ))}

              {beanSvgs &&
                beanSvgs.map((svg, i) => {
                  return (
                    <Col xs={4} lg={3} key={i}>
                      <div
                        onClick={() => {
                          setIndexOfBeanToDisplay(i);
                          setDisplayBean(true);
                        }}
                      >
                        <Bean
                          imgPath={svg}
                          alt='bean'
                          className={classes.beanImg}
                          wrapperClassName={classes.beanWrapper}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Playground;
